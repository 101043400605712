import Vue from "vue";

import "../plugins/config.js";
import "../plugins/service/index.js";
import "../plugins/languagen/index.js";
import "./Util.controller";
import { ENDPOINTS, ID_LOGIN_SI } from "../plugins/service/Endpoints.js";
import router from "@/plugins/router/index.js";

import _ from "lodash";

// CONFIGURAÇÃO DE COSTUMIZAÇÃO

Vue.prototype.$usuario = new Vue({
    data() {
        return {
            // TODO: VARIAVEIS
            ENDPOINTS: ENDPOINTS,
            ID_LOGIN_SI: ID_LOGIN_SI,
            logins: [],
            login: {},
            win: [],
            modalEvento: {
                show: false,
                text: "",
            },
            access_token: false,
            menu: {
                show: false,
                loading: false,
            },
            cadastro: {
                nome: "",
                sobrenome: "",
                email: "",
                email2: "",
                senha: "",
                senha2: "",
                old_password: "",
                celular: "",

                telefone: "",
                celularComCodigoPais: "",
                telefoneComCodigoPais: "",
                cpf: "",
                empresa_cnpj: "",
                passaporte: "",
                nacionalidade: "",
                dt_nascimento: "",
                dt_nascimento_temp: "",
                termos: false,
                loading: false,
                end_cep: "",
                end_pais: "",
                end_estado: "",
                end_cidade: "",
                end_bairro: "",
                end_numero: "",
                end_logradouro: "",
                error: {
                    nome: [],
                    sobrenome: [],
                    email: [],
                    email2: [],
                    old_password: [],
                    senha: [],
                    senha2: [],
                    nacionalidade: [],
                    dt_nascimento: [],
                    dt_nascimento_temp: [],
                    termos: [],
                    cpf: [],
                    empresa_cnpj: [],
                },
            },
            termoCompra: "",
            modalAlertSucesso: false,
            logado: false,
            showModalLogin: false,
            showModalLoginEsqueciSenha: false,
            showModalLoginAgencia: false,
            showModalLogin_cadastro: false,
            showModalLoginPersist: false,
        };
    },
    methods: {
        rotasEmails() {
            this.getloginSocialCadastro();
            this.confirmEmail();
            this.esqueceuSenha();
            this.openModalAgencia();
            this.openModalLogin();
        },

        openModalLogin() {
            let dados = {
                token: this.getSearchParams(
                    document.location.search,
                    "fazerlogin"
                ),
            };
            if (dados.token && !this.$axios.getLocal("session")) {
                this.$axios.setLocal("gorouter", "MeusPedidos");
                this.showModalLogin = true;
            }
        },

        openModalAgencia() {
            let dados = {
                token: this.getSearchParams(
                    document.location.search,
                    "agencia"
                ),
            };
            if (dados.token) {
                const self = this;
                self.showModalLogin = true;
                setTimeout(() => {
                    self.showModalLoginAgencia = true;
                }, 800);
            }
        },
        openModalLogin() {
            let dados = {
                token: this.getSearchParams(
                    document.location.search,
                    "fazerlogin"
                ),
            };
            if (dados.token && !this.$axios.getLocal("session")) {
                this.$axios.setLocal("gorouter", "MeusPedidos");
                this.showModalLogin = true;
            }
        },
        confirmEmail() {
            let dados = {
                token: this.getSearchParams(
                    document.location.search,
                    "validatemail"
                ),
            };
            if (dados.token) {
                this.$axios.post(this.ENDPOINTS.CONFIRMAREMAIL, dados);
            }
        },

        esqueceuSenha() {
            let dados = {
                token: this.getSearchParams(
                    document.location.search,
                    "esqueceusenha"
                ),
            };
            if (dados.token) {
                const self = this;
                self.showModalLogin = true;
                setTimeout(() => {
                    self.showModalLoginEsqueciSenha = true;
                }, 800);
            }
        },

        getLogins() {
            // TODO: getLogins

            this.$axios
                .get(this.ENDPOINTS.GETLOGINS, false, false)
                .then((response) => {
                    this.logins = response.logins;
                })
                .catch(() => {});
        },

        getTermoCompra() {
            // TODO: getTermoCompra
            this.$axios
                .get(this.ENDPOINTS.GETTERMOCOMPRA, false, false)
                .then((response) => {
                    this.termoCompra = response;
                });
        },

        getLogin(email, senha) {
            // TODO: getLogin
            // console.log(email, senha)

            let login = Object.assign({}, { email, senha });
            let dados = {
                _username: login.email.trim(),
                _password: login.senha,
            };

            if (login.termo) {
                dados._termo = true;
                this.$superingresso.aceiteTermo(login.email).then(() => {
                    this.fazerLogin(dados);
                });
                return;
            }

            const root = document.getElementById("app").__vue__;
            root.login = {
                email: login.email,
                senha: login.senha,
            };

            this.fazerLogin(dados);
        },

        fazerLogin(dados) {
            this.cadastro.loading = true;

            this.showModalLoginPersist = true;

            this.$axios
                .post(this.ENDPOINTS.LOGIN + this.ID_LOGIN_SI, dados)
                .then((response) => {
                    if (response && response.access_token) {
                        this.$axios.setLocal("token", response.access_token);
                        this.$axios
                            .get(this.ENDPOINTS.DADOSCADASTRAIS)
                            .then((res) => {
                                let dados = res;
                                if (dados) {
                                    this.$axios.setLocal("session", dados);
                                    this.$axios.setLocal("confirm_login", true);
                                    this.logado = true;
                                    router.push({ name: "Home" });
                                    document.location.reload();
                                } else {
                                    if (location.pathname != "Home") {
                                        router.push({ name: "Home" });
                                    } else {
                                        window.scrollTo({
                                            top: 750,
                                            left: 0,
                                            behavior: "smooth",
                                        });
                                    }
                                }
                            });
                    }
                    this.cadastro.loading = false;
                })
                .catch(() => {
                    this.cadastro.loading = false;
                });
        },

        loginSocial(login, mobile) {
            // TODO: loginSocial
            this.menu.show = false;
            // CONFIGURAÇÕES DA JANELA

            const dualScreenLeft =
                window.screenLeft !== undefined
                    ? window.screenLeft
                    : screen.left;
            const dualScreenTop =
                window.screenTop !== undefined ? window.screenTop : screen.top;
            const width = window.innerWidth
                ? window.innerWidth
                : document.documentElement.clientWidth
                ? document.documentElement.clientWidth
                : screen.width;
            const height = window.innerHeight
                ? window.innerHeight
                : document.documentElement.clientHeight
                ? document.documentElement.clientHeight
                : screen.height;
            const left = width / 2 - 500 / 2 + dualScreenLeft;
            const top = height / 2 - 400 / 2 + dualScreenTop;
            const settings =
                "height = 500, width = 600, top = " +
                top +
                ", left = " +
                left +
                ", scrollbars = true, resizable";

            if (mobile === true) {
                this.$axios.setLocal("mobile_social", true);
                document.location.href = login.url;
                return;
            }

            location.href = login.url;
        },

        async recuperarSenha(email) {
            // TODO: recuperarSenha

            let recaptcha = "";

            if (
                this.$config.conf.site_key_recaptcha &&
                this.$config.conf.recaptcha_ativo
            ) {
                recaptcha = (await window.grecaptcha.getResponse()) || "";
            }

            if (
                this.$config.conf.site_key_recaptcha &&
                this.$config.conf.recaptcha_ativo &&
                recaptcha == ""
            ) {
                window.grecaptcha.reset();
                return;
            }

            this.menu.loading = true;

            let dados = {
                username: email,
                hashcaptcha: recaptcha,
            };

            this.$axios
                .post(this.ENDPOINTS.RECUPERARSENHA, dados)
                .then((response) => {
                    this.menu.loading = false;
                    this.menu.show = false;
                    if (
                        this.$config.conf.site_key_recaptcha &&
                        this.$config.conf.recaptcha_ativo
                    ) {
                        window.grecaptcha.reset();
                    }
                    if (response && response.sucesso) {
                        {
                            this.$config.alert(response.msg_sucesso);
                            return;
                        }
                    }
                })
                .catch(() => {
                    this.menu.loading = false;
                    this.menu.show = false;
                    if (
                        this.$config.conf.site_key_recaptcha &&
                        this.$config.conf.recaptcha_ativo
                    ) {
                        window.grecaptcha.reset();
                    }
                });
        },

        recoverySenha(dados) {
            // TODO: recoverySenha

            this.$axios
                .post(this.ENDPOINTS.RECUPERARSENHANOVASENHA, dados)
                .then((response) => {
                    if (response) {
                        router.push({ name: "Home" });
                    }
                });
        },

        async cadastrarPessoa() {
            // TODO: cadastrarPessoa
            if (
                this.$config.conf.site_key_recaptcha &&
                this.$config.conf.recaptcha_ativo
            ) {
                let recaptcha = this.$config.conf.recaptcha_ativo
                    ? await window.grecaptcha.getResponse()
                    : null;

                if (recaptcha == "") {
                    recaptcha = await window.grecaptcha.getResponse();
                    window.grecaptcha.reset();
                    return;
                }
            }
            this.cadastro.loading = true;
            let dataNascimento = this.cadastro.dt_nascimento;

            this.cadastro.email = this.cadastro.email.trim();
            this.cadastro.username = this.cadastro.email.trim();
            this.cadastro.dt_nascimento = dataNascimento;
            this.cadastro.celular = this.cadastro.celularComCodigoPais;
            this.cadastro.telefone = this.cadastro.telefoneComCodigoPais;
            this.cadastro.recaptcha = recaptcha;
            this.cadastro.password = this.cadastro.senha;

            if (this.showModalLoginAgencia) {
                this.cadastro.idpessoa_tipo =
                    this.$config.regrasDoNegocio.idagencia;
                this.cadastro.captchaWidgetId = 0;
                this.cadastro._cpassword = this.cadastro.senha;
                this.cadastro.empresa_fantasia = this.cadastro.empresa_razao;
                this.cadastro.hashcaptcha = recaptcha;

                let params = [this.cadastro, "SuperIngressoWebV2"];

                this.$superingresso
                    .CadastroPessoa(params)
                    .then((response) => {
                        this.cadastro.loading = false;

                        if (response) {
                            this.showModalLogin = false;
                            this.$axios.setLocal("confirmCadastro", true);
                            this.$axios.modal.titulo =
                                this.$t_("TITULO_AGENCIA");
                            this.$axios.modal.conteudo =
                                this.$t_("CONTEUDO_AGENCIA");
                            this.$axios.modal.showclose = true;
                            this.$axios.modal.show = true;
                            this.login.loginAgencia = false;
                            this.login.showModalLogin = false;
                            this.showModalLogin_cadastro = false;
                        }
                    })
                    .catch(() => {
                        this.cadastro.loading = false;
                    });

                return;
            }

            this.cadastro.idioma = this.$axios.getLocal("lang")
                ? this.$axios.getLocal("lang").data.substring(0, 2)
                : "pt";
            let dados = {};
            Object.keys(this.cadastro).forEach((key) => {
                dados[`_${key}`] =
                    `${this.cadastro[key]}` == "null"
                        ? null
                        : `${this.cadastro[key]}`;
            });

            delete dados._error;

            this.$axios
                .post(this.ENDPOINTS.CADASTROPESSOA, dados)
                .then((response) => {
                    this.cadastro.loading = false;

                    if (response) {
                        this.$axios.setLocal("confirmCadastro", true);
                        if (this.$axios.getLocal("token")) {
                            router.push({ name: "Home" });
                            // document.location.reload()
                        } else {
                            this.getLogin(
                                this.cadastro.email.trim(),
                                this.cadastro.senha
                            );
                        }
                    }
                })
                .catch(() => {
                    this.cadastro.loading = false;
                });
        },

        async getPerfil() {
            // TODO: getPerfil
            return await this.$axios.get(this.ENDPOINTS.DADOSCADASTRAIS);
        },

        getSearchParamsModal(k) {
            // TODO: getSearchParamsModal
            const p = {};
            const self = this;
            if (!self.win.closed && self.win.location.search) {
                self.win.document.URL.replace(
                    /[?&]+([^=&]+)=([^&]*)/gi,
                    function (s, k, v) {
                        p[k] = v;
                        let saida = k ? p[k] : p;

                        if (self.win.name === "facebook") {
                            saida = saida.substring(0, saida.length - 4);
                        }

                        self.$axios.setLocal("token", saida);
                        self.$axios.setLocal("disabled", true);
                        self.win.close();
                        self.access_tokenSocial();
                    }
                );
            }

            self.access_token = false;
        },

        getSearchParams(win, k) {
            // TODO: getSearchParams
            const urlParams = new URLSearchParams(win);
            return urlParams.get(k);
        },

        async autoLogin() {
            // TODO: autoLogin
            const sleep = (segundos) => {
                return new Promise((resolve) =>
                    setTimeout(resolve, segundos * 1000)
                );
            };
            if (
                this.$axios.getLocal("token") &&
                (!this.$axios.getLocal("session") ||
                    (this.$axios.getLocal("session") &&
                        this.$axios.getLocal("session").data == true))
            ) {
                this.getPerfil().then((response) => {
                    if (response) {
                        this.$axios.setLocal("session", response);
                        if (this.$axios.getLocal("gorouter")) {
                            let rota = this.$axios.getLocal("gorouter").data;
                            this.$axios.remove("gorouter");
                            router.push({ name: rota });
                        }
                        //location.reload()
                    }
                });
            }

            await sleep(1);

            if (this.$axios.getLocal("session")) {
                let session = this.$axios.getLocal("session").data;
                if (
                    (session.idpessoa_tipo ==
                        this.$config.regrasDoNegocio.idagencia &&
                        !session.empresa_cnpj &&
                        !session.empresa_razao) ||
                    (session.idpessoa_tipo !=
                        this.$config.regrasDoNegocio.idagencia &&
                        ((!session.cpf &&
                            !session.passaporte &&
                            !session.empresa_cnpj) ||
                            (this.$config.dados.editarEndereco &&
                                !session.end_estado &&
                                !session.end_pais)))
                ) {
                    this.$config.alertWarn(this.$t_("preencha"), "green");
                    this.$axios.rota("Perfil");
                }

                // RESERVA OS PRODUTOS NO CARRINHO ONLINE

                if (
                    this.$axios.getLocal("local_carrinho") &&
                    (session.cpf ||
                        session.passaporte ||
                        session.empresa_cnpj) &&
                    session.end_estado &&
                    session.end_pais
                ) {
                    let local_carrinho =
                        this.$axios.getLocal("local_carrinho").data;
                    const self = this;

                    local_carrinho.forEach((item, index) => {
                        self.$carrinho.setCarrinhoLocal(item).then(() => {
                            // Atualizando carrinho após login
                            self.$carrinho.atualizaCarrinho();

                            self.$axios.remove("local_carrinho");
                            setTimeout(() => {
                                self.$axios.rota("Checkout");
                            }, 1000);
                        });
                    });
                }
            }
        },

        async getloginSocialCadastro() {
            // TODO: getloginSocialCadastro

            let link = document.location.search;

            if (link.indexOf("access_token") != -1) {
                let access_token = await this.getSearchParams(
                    document.location.search,
                    "access_token"
                );
                this.$axios.setLocal("token", access_token);
                this.getPerfil().then((response) => {
                    this.$axios.setLocal("session", response);
                    if (this.$axios.getLocal("gorouter")) {
                        let rota = this.$axios.getLocal("gorouter").data;
                        this.$axios.remove("gorouter");
                        router.push({ name: rota });
                    }
                });
            }

            return false;
        },

        async access_tokenSocial() {
            // TODO: access_tokenSocial

            if (
                !this.$axios.getLocal("token") ||
                this.$axios.getLocal("token").data.length == 0
            ) {
                return;
            }
            this.$axios.get(this.ENDPOINTS.DADOSCADASTRAIS).then((response) => {
                let dados = response;

                this.cadastro.nome = "";
                this.cadastro.sobrenome = "";
                this.cadastro.email = "";
                this.cadastro.email2 = "";

                if (dados != null && !dados.nacionalidade) {
                    this.cadastro.nome = this.getNome(dados.nome);
                    this.cadastro.sobrenome = this.getSobrenome(dados.nome);
                    this.cadastro.email = dados.email;
                    this.cadastro.email2 = dados.email;

                    this.showModalLogin_cadastro = true;
                }

                // CASO TENHA SENHA TEM QUE FAZER A LIGAÇÃO PARA IR PARA A TELA DE FASES
                if (dados && dados.nacionalidade) {
                    this.$axios.setLocal("session", dados);
                    this.$config.modalAlertSucesso = true;
                    this.$axios.setLocal("confirm_login", true);
                    this.logado = true;
                    // router.push({ name: 'JogosVenda' });
                    document.location.reload();
                }
            });
        },

        getNome(nome) {
            // TODO: getNome
            if (nome && nome.split(" ")) {
                return nome.split(" ")[0];
            }
            return nome;
        },

        getSobrenome(nome) {
            // TODO: getSobrenome
            if (nome && nome.split(" ")) {
                let sobrenome = "";
                nome = nome.split(" ");
                for (let i = 0; i < nome.length; i++) {
                    if (i > 0) {
                        sobrenome += nome[i] + " ";
                    }
                }
                return sobrenome;
            }
            return nome;
        },

        validarData(data) {
            // TODO: validarData

            if (this.$axios.getLocal("lang")) {
                this.$moment.locale(this.$axios.getLocal("lang").data);
            }

            if (
                this.$axios.getLocal("lang") &&
                this.$axios.getLocal("lang").data == "en-US"
            ) {
                data = data.split("/");
                data = `${data[2]}-${data[0]}-${data[1]}`;
            } else {
                data = data.split("/");
                data = `${data[2]}-${data[1]}-${data[0]}`;
            }

            let idade = this.$moment(data).fromNow();

            if (this.$util.removerCaracteres(idade) > 18) {
                return true;
            }

            return false;
        },

        // getSexo() { // TODO: getSexo
        //     return [
        //         { dsc: this.$t_('nao_informado'), value: '1' },
        //         { dsc: this.$t_('masculino'), value: '2' },
        //         { dsc: this.$t_('feminino'), value: '3' }
        //     ]
        // },

        removerCaracteres(v) {
            // TODO: removerCaracteres
            return v.toString().replace(/\D+/g, "");
        },
        async validarEmail(field) {
            // TODO: validarEmail
            // VERIFICA SE TEM O @ E O . ALÉM DO TAMANHO DO DOMINIO
            let usuario = field.substring(0, field.indexOf("@"));
            let dominio = field.substring(field.indexOf("@") + 1, field.length);

            if (
                usuario.length >= 1 &&
                dominio.length >= 3 &&
                usuario.search("@") == -1 &&
                dominio.search("@") == -1 &&
                usuario.search(" ") == -1 &&
                dominio.search(" ") == -1 &&
                dominio.search(".") != -1 &&
                dominio.indexOf(".") >= 1 &&
                dominio.lastIndexOf(".") < dominio.length - 1
            ) {
                return true;
            } else {
                return false;
            }
        },

        validarEmailSync(field) {
            // TODO: validarEmailSync
            // VERIFICA SE TEM O @ E O . ALÉM DO TAMANHO DO DOMINIO
            let usuario = field.substring(0, field.indexOf("@"));
            let dominio = field.substring(field.indexOf("@") + 1, field.length);

            if (
                usuario.length >= 1 &&
                dominio.length >= 3 &&
                usuario.search("@") == -1 &&
                dominio.search("@") == -1 &&
                usuario.search(" ") == -1 &&
                dominio.search(" ") == -1 &&
                dominio.search(".") != -1 &&
                dominio.indexOf(".") >= 1 &&
                dominio.lastIndexOf(".") < dominio.length - 1
            ) {
                return true;
            } else {
                return false;
            }
        },

        validaSenha(v) {
            // TODO: validaSenha
            // 1 caracterer maiusculo;
            // 1 caracterer minusculo;
            // 1 simbolo;
            // 1 numero;
            // 6 caracteres no minimo;

            let nivel = 0;
            let caracteres = 0;
            if (v) {
                caracteres = v.toString().length;
            }
            let car = "";

            let temMinusculo = false;
            let temMaiusculo = false;
            let temCaractere = false;
            let temNumero = false;
            let temLength = false;

            for (let index = 0; index < caracteres; index++) {
                car = v.charAt(index);

                // TESTA SE TEM NUMERO
                if (!isNaN(car * 1) && !temNumero) {
                    temNumero = true;
                    nivel += 1;
                }
                // TESTA SE É MAIUSCULO, MINUSCULO E SE TEM MAIS DE 6 CARACTERES
                else {
                    if (
                        /^[a-zA-Z0-9]+$/.test(car) &&
                        isNaN(car) &&
                        caracteres > 6 &&
                        !temLength
                    ) {
                        temLength = true;
                        nivel += 1;
                    }

                    if (
                        /^[a-zA-Z0-9]+$/.test(car) &&
                        isNaN(car) &&
                        car === car.toUpperCase() &&
                        !temMaiusculo
                    ) {
                        temMaiusculo = true;
                        nivel += 1;
                    }

                    if (
                        /^[a-zA-Z0-9]+$/.test(car) &&
                        isNaN(car) &&
                        car === car.toLowerCase() &&
                        !temMinusculo
                    ) {
                        temMinusculo = true;
                        nivel += 1;
                    }

                    // TESTA SE TEM CARACTERE
                    if (!temCaractere) {
                        if (!/^[a-zA-Z0-9]+$/.test(car)) {
                            temCaractere = true;
                            nivel += 1;
                        }
                    }
                }
            }

            return nivel * 2 * 10;
        },

        scrollCadastro() {
            // TODO: scrollCadastro
            window.scrollTo({
                top: 720,
                left: 0,
                behavior: "smooth",
            });
        },

        async trocarSenha() {
            // TODO: trocarSenha
            if (
                this.cadastro &&
                this.cadastro.old_password &&
                this.cadastro.old_password.length == 0
            ) {
                this.$config.alertWarn(this.$t_("senha_atual"));
                this.cadastro.error.old_password = this.$t_("senha_atual");
                return;
            }
            if (this.validaSenha(this.cadastro.senha) < 60) {
                this.$config.alertWarn(this.$t_("preencha_senha_corret"));
                this.cadastro.error.senha = this.$t_("preencha_senha_corret");
                return;
            }
            if (this.cadastro.senha != this.cadastro.senha2) {
                this.$config.alertWarn(this.$t_("senhas_incompativeis"));
                this.cadastro.error.senha2 = this.$t_("senhas_incompativeis");
                return;
            }
            this.$axios
                .post(this.ENDPOINTS.TROCARSENHA, {
                    oldpassword: this.cadastro.old_password,
                    password: this.cadastro.senha,
                })
                .then((response) => {
                    if (response) {
                        sessionStorage.removeItem("token");
                        localStorage.removeItem("token");
                        this.$axios.destroy(true);
                    }
                });
        },

        async validarCadastro() {
            // TODO: validarCadastro

            if (
                this.cadastro.email.length < 2 ||
                this.cadastro.email == "" ||
                (await !this.validarEmail(this.cadastro.email))
            ) {
                this.$config.alertWarn(this.$t_("preencha_email_corret"));
                this.cadastro.error.email = this.$t_("preencha_email_corret");
                this.scrollCadastro();
                return;
            }
            if (this.cadastro.email !== this.cadastro.email2) {
                this.$config.alertWarn(this.$t_("emails_incompativeis"));
                this.cadastro.error.email2 = this.$t_("emails_incompativeis");
                this.scrollCadastro();
                return;
            }

            if (!this.$superingresso.validarEmail(this.cadastro.email)) {
                this.scrollCadastro();
                return;
            }

            if (!this.$axios.getLocal("token")) {
                if (this.validaSenha(this.cadastro.senha) < 60) {
                    this.$config.alertWarn(this.$t_("preencha_senha_corret"));
                    this.cadastro.error.senha = this.$t_(
                        "preencha_senha_corret"
                    );
                    this.scrollCadastro();
                    return;
                }
                if (this.cadastro.senha != this.cadastro.senha2) {
                    this.$config.alertWarn(this.$t_("senhas_incompativeis"));
                    this.cadastro.error.senha2 = this.$t_(
                        "senhas_incompativeis"
                    );
                    this.scrollCadastro();
                    return;
                }
            }

            if (
                !this.cadastro.cpf &&
                !this.cadastro.passaporte &&
                !this.cadastro.empresa_cnpj
            ) {
                this.$config.alertWarn(this.$t_("preencha_doc_corret"));

                return;
            }

            if (this.showModalLoginAgencia) {
                if (
                    (!this.cadastro.empresa_cnpj &&
                        this.cadastro.empresa_razao < 2) ||
                    this.cadastro.empresa_razao == ""
                ) {
                    this.$config.alertWarn(this.$t_("preencha_dados"));
                    return;
                }

                this.cadastro.empresa_fantasia = this.cadastro.empresa_fantasia
                    ? this.cadastro.empresa_fantasia
                    : this.cadastro.empresa_razao;
                this.cadastro.nome = this.cadastro.empresa_razao;

                if (
                    !this.cadastro.telefoneComCodigoPais ||
                    this.cadastro.telefoneComCodigoPais.length < 9 ||
                    this.cadastro.telefoneComCodigoPais == ""
                ) {
                    this.$config.alertWarn(this.$t_("preencha_tel_corret"));

                    return;
                }
            } else {
                if (this.cadastro.nome.length < 2 || this.cadastro.nome == "") {
                    this.$config.alertWarn(this.$t_("preencha_nome_corret"));
                    this.cadastro.error.nome = this.$t_("preencha_nome_corret");
                    this.scrollCadastro();
                    return;
                }

                if (!this.validarData(this.cadastro.dt_nascimento)) {
                    this.$config.alertWarn(this.$t_("idade_error"));
                    this.cadastro.error.dt_nascimento = this.$t_("idade_error");
                    this.scrollCadastro();
                    return;
                }

                if (
                    !this.cadastro.celularComCodigoPais ||
                    this.cadastro.celularComCodigoPais.length < 10 ||
                    this.cadastro.celularComCodigoPais == ""
                ) {
                    this.$config.alertWarn(this.$t_("preencha_cel_corret"));
                    return;
                }

                if (
                    !this.cadastro.celular ||
                    this.cadastro.celular.length < 5 ||
                    this.cadastro.celular == ""
                ) {
                    this.$config.alertWarn(this.$t_("preencha_cel_corret"));
                    return;
                }
            }

            if (this.$config.dados.editarEndereco) {
                if (
                    !this.cadastro.end_cep ||
                    this.cadastro.end_cep.length < 5 ||
                    this.cadastro.end_cep == ""
                ) {
                    this.$config.alertWarn(this.$t_("preencha_cep"));
                    return;
                }
                if (
                    !this.cadastro.end_pais ||
                    this.cadastro.end_pais.length < 2 ||
                    this.cadastro.end_pais == ""
                ) {
                    this.$config.alertWarn(this.$t_("preencha_pais_corret"));
                    return;
                }
                if (
                    !this.cadastro.end_cidade ||
                    this.cadastro.end_cidade.length < 2 ||
                    this.cadastro.end_cidade == ""
                ) {
                    this.$config.alertWarn(this.$t_("preencha_cidade_corret"));
                    return;
                }
                if (
                    !this.cadastro.end_estado ||
                    this.cadastro.end_estado == ""
                ) {
                    this.$config.alertWarn(this.$t_("preencha_estado_corret"));
                    return;
                }
                if (
                    !this.cadastro.end_logradouro ||
                    this.cadastro.end_logradouro.length < 2 ||
                    this.cadastro.end_logradouro == ""
                ) {
                    this.$config.alertWarn(this.$t_("preencha_end_corret"));
                    return;
                }
                if (
                    !this.cadastro.end_numero ||
                    this.cadastro.end_numero.length < 1 ||
                    this.cadastro.end_numero == ""
                ) {
                    this.$config.alertWarn(this.$t_("preencha_numero_corret"));
                    return;
                }
            }

            if (this.cadastro.termos == false) {
                this.$config.alertWarn(this.$t_("deve_aceitar"));
                this.cadastro.error.termos = this.$t_("deve_aceitar");
                return;
            }

            let recaptcha =
                this.$config.conf.site_key_recaptcha &&
                this.$config.conf.recaptcha_ativo
                    ? await window.grecaptcha.getResponse()
                    : null;

            if (
                this.$config.conf.site_key_recaptcha &&
                recaptcha == "" &&
                this.$config.conf.recaptcha_ativo
            ) {
                this.$config.dados.recaptcha == "visible"
                    ? window.grecaptcha.reset()
                    : window.grecaptcha.execute();
                // this.$config.alertWarn(this.$t_('recaptcha_invalido'));
                return;
            }

            if (this.$axios.getLocal("token")) {
                this.cadastro.senha = "";
                this.cadastro.senha2 = "";
                this.setEditLoginSocial();
                return;
            }

            this.cadastrarPessoa();
        },

        onchangerEmail() {
            // TODO: onchangerEmail
            this.$axios
                .post(this.ENDPOINTS.VALIDAREMAIL, {
                    email: this.cadastro.email,
                })
                .then((response) => {
                    if (!response) {
                        this.cadastro.error.email =
                            this.$t_("email_cadastrado");
                    }
                });
        },

        async onchangerData() {
            // TODO: onchangerData
            setTimeout(async () => {
                if (this.cadastro.dt_nascimento.length > 0) {
                    if (await this.validarData(this.cadastro.dt_nascimento)) {
                        this.cadastro.error.dt_nascimento =
                            this.$t_("idade_error");
                        return;
                    }
                }
            }, 750);
        },

        setEditLoginSocial() {
            // TODO: setEditLoginSocial
            let dados = this.cadastro;
            let dataNascimento = dados.dt_nascimento;

            if (this.$axios.getLocal("lang").data == "en-US") {
                var datearray = dataNascimento.split("/");
                var newdate = `${datearray[2]}-${datearray[0]}-${datearray[1]}`;
                dataNascimento = newdate;
            } else {
                var datearray = dataNascimento.split("/");
                var newdate = `${datearray[2]}-${datearray[1]}-${datearray[0]}`;
                dataNascimento = newdate;
            }

            dados.nome = this.cadastro.nome + " " + this.cadastro.sobrenome;

            this.$axios
                .post(this.ENDPOINTS.DADOSCADASTRAIS, dados)
                .then((response) => {
                    if (response) {
                        this.$axios.setLocal("confirmEdit", true);
                        let dados = response;
                        if (dados.nacionalidade != "") {
                            this.$axios.setLocal("session", dados);
                            router.push({ name: "JogosVenda" });
                            document.location.reload();
                        } else {
                            if (
                                location.pathname != "/pt/intencaodecompra" ||
                                location.pathname != "/es/intenciondecompra" ||
                                location.pathname != "/en/buy-intention"
                            ) {
                                router.push({ name: "JogosVenda" });
                                window.scrollTo({
                                    top: 750,
                                    left: 0,
                                    behavior: "smooth",
                                });
                            } else {
                                window.scrollTo({
                                    top: 750,
                                    left: 0,
                                    behavior: "smooth",
                                });
                            }
                        }
                    }
                });
        },

        setEdit(dados) {
            // TODO: setEdit
            let temp = Object.assign({}, dados);

            if (this.$config.dados.editarEndereco) {
                if (
                    !temp.end_cep ||
                    temp.end_cep.length < 5 ||
                    temp.end_cep == ""
                ) {
                    this.$config.alertWarn(this.$t_("preencha_cep"));
                    return;
                }
                if (
                    !temp.end_pais ||
                    temp.end_pais.length < 2 ||
                    temp.end_pais == ""
                ) {
                    this.$config.alertWarn(this.$t_("preencha_pais_corret"));
                    return;
                }
                if (
                    !temp.end_cidade ||
                    temp.end_cidade.length < 2 ||
                    temp.end_cidade == ""
                ) {
                    this.$config.alertWarn(this.$t_("preencha_cidade_corret"));
                    return;
                }
                if (!temp.end_estado || temp.end_estado == "") {
                    this.$config.alertWarn(this.$t_("preencha_estado_corret"));
                    return;
                }
                if (
                    !temp.end_logradouro ||
                    temp.end_logradouro.length < 2 ||
                    temp.end_logradouro == ""
                ) {
                    this.$config.alertWarn(this.$t_("preencha_end_corret"));
                    return;
                }
                if (
                    !temp.end_numero ||
                    temp.end_numero.length < 1 ||
                    temp.end_numero == ""
                ) {
                    this.$config.alertWarn(this.$t_("preencha_numero_corret"));
                    return;
                }
            }

            if (!temp.termos) {
                this.$config.alertWarn(this.$t_("deve_aceitar"));
                return;
            }

            if (!temp.empresa_cnpj) {
                let data = temp.dt_nascimento;

                if (
                    this.$axios.getLocal("lang") &&
                    this.$axios.getLocal("lang").data == "en-US"
                ) {
                    data = data.split("/");
                    data = `${data[2]}-${data[0]}-${data[1]}`;
                } else {
                    data = data.split("/");
                    data = `${data[2]}-${data[1]}-${data[0]}`;
                }

                temp.dt_nascimento = data;

                if (temp.nome.length < 2 || temp.nome == "") {
                    this.$config.alertWarn(this.$t_("preencha_nome_corret"));
                    temp.error.nome = this.$t_("preencha_nome_corret");
                    this.scrollCadastro();
                    return;
                }

                if (!this.validarData(temp.dt_nascimento)) {
                    this.$config.alertWarn(this.$t_("idade_error"));
                    temp.error.dt_nascimento = this.$t_("idade_error");
                    this.scrollCadastro();
                    return;
                }

                if (
                    !temp.celularComCodigoPais ||
                    temp.celularComCodigoPais.length < 10 ||
                    temp.celularComCodigoPais == ""
                ) {
                    this.$config.alertWarn(this.$t_("preencha_cel_corret"));
                    return;
                }

                if (
                    !temp.celular ||
                    temp.celular.length < 5 ||
                    temp.celular == ""
                ) {
                    this.$config.alertWarn(this.$t_("preencha_cel_corret"));
                    return;
                }
            } else {
                if (
                    (!temp.empresa_cnpj && temp.empresa_razao < 2) ||
                    temp.empresa_razao == ""
                ) {
                    this.$config.alertWarn(this.$t_("preencha_dados"));
                    return;
                }

                temp.empresa_fantasia = temp.empresa_fantasia
                    ? temp.empresa_fantasia
                    : temp.empresa_razao;
                temp.nome = temp.empresa_razao;

                if (
                    !temp.telefoneComCodigoPais ||
                    temp.telefoneComCodigoPais.length < 9 ||
                    temp.telefoneComCodigoPais == ""
                ) {
                    this.$config.alertWarn(this.$t_("preencha_tel_corret"));

                    return;
                }
            }

            temp.celular = temp.celularComCodigoPais;

            if (this.showModalLoginAgencia) {
                temp.telefone = temp.telefoneComCodigoPais;
            }

            this.$axios
                .post(this.ENDPOINTS.DADOSCADASTRAIS, temp)
                .then((response) => {
                    this.$axios.setLocal("session", temp);
                    if (response) {
                        this.$axios.setLocal("confirmEdit", true);
                        this.$config.alert(this.$t_("alterado"));
                        setTimeout(() => {
                            document.location.href = "/";
                        }, 1000);
                    } else {
                        setTimeout(() => {
                            document.location.reload();
                        }, 1000);
                    }
                });
        },
    },
});

Vue.prototype.$usuario.getLogins();
// Vue.prototype.$usuario.getLogin({email: "abraao.nurse@gmail.com", senha:"1e1bbd7dac1355f280643072a5d5cbc2"});

// Vue.prototype.$usuario.validarData('30/12/1987')
