import Vue from "vue";
import moment from "moment";
import _ from "lodash";

import "../plugins/config.js";
import "../plugins/service/index.js";
import "../plugins/languagen/index.js";
import "@/controllers/SuperIngresso.controller.js";
import "@/controllers/Google.controller.js";
import { ENDPOINTS } from "../plugins/service/Endpoints.js";
import "./RegradeNegocio.controller";
import Util from "./Util.controller.js";

Vue.prototype.$carrinho = new Vue({
    data() {
        // FUNC: VARIAVEIS
        return {
            ENDPOINTS: ENDPOINTS,
            qtdReservada: 0,
            pagina: 1,
            paginacao: 1,
            statusPagina: "PA;PC",
            totalResultados: 5,
            qtd: 1,
            apresentacoes: [],
            interesseTermos: false,
            showAddPedido: false,
            showModalAjEndereco: false,
            AddPedido: [],
            reservas: [],
            carrinho: [],
            carrinho_: [],
            carrinho_finalizar: [],
            carrinho_finalizar_select: "",
            pedidos: [],
            endereco: [],
            pagamento: [],
            bandeiraspagamento: [],
            dadoslocalhost: [],
            ingresso_select: "ETICKET",
            help: "",
            resumo: {
                qtd: 0,
                valor: 0,
                subtotal: 0,
                valor_cupom: 0,
                valor_taxa: 0,
                valor_combo: 0,
                cupom: "",
            },
            itensReservados: 0,
            exp: 0,
            pagamentoFail: false,
            botonactive: false,
            carrinhoVazio: false,
            loading: false,
            liberar: false,
            ApresencacoesArray: [],
            reserva: [],
            pagamentoRealizado: false,
            dados: {
                recaptcha: "",
            },
            card: {
                number: "",
                cvc: "",
                holderName: "",
                expiryMonth: "",
                expiryYear: "",
                bandeira: "",
                validade: "",
                fullyear_: "",
                validado: false,
                salvar: true,
            },
            modalCard: false,
            gateway: [],
            gateway_raw: [],
            gatewaySelected: [],
            modalPagamento: {
                show: false,
                persistent: false,
            },
            infoCartoes: {
                aura: {
                    name: "Aura",
                    numero: ["50"],
                    numero_intervalo: [],
                    numero_tamanho: [16],
                    cvv_tamanho: [3],
                    mask: "#### #### #### ####",
                },
                cabal: {
                    name: "Cabal",
                    numero: ["589657", "603522"],
                    numero_intervalo: [
                        ["604201", "604219"],
                        ["604221", "604400"],
                    ],
                    numero_tamanho: [16],
                    cvv_tamanho: [3],
                    mask: "#### #### #### ####",
                },
                elo: {
                    name: "ELO",
                    numero: [
                        "4011",
                        "401178",
                        "401179",
                        "431274",
                        "438935",
                        "451416",
                        "457393",
                        "4576",
                        "457631",
                        "457632",
                        "504175",
                        "627780",
                        "636297",
                        "636368",
                        "636369",
                    ],
                    numero_intervalo: [
                        ["506699", "506778"],
                        ["509000", "509999"],
                        ["650031", "650033"],
                        ["650035", "650051"],
                        ["650405", "650439"],
                        ["650485", "650538"],
                        ["650541", "650598"],
                        ["650700", "650718"],
                        ["650720", "650727"],
                        ["650901", "650920"],
                        ["651652", "651679"],
                        ["655000", "655019"],
                        ["655021", "655058"],
                    ],
                    numero_tamanho: [16],
                    cvv_tamanho: [3],
                    mask: "#### #### #### ####",
                },
                discover: {
                    name: "Discover",
                    numero: ["6011", "622", "64", "65"],
                    numero_intervalo: [],
                    numero_tamanho: [16],
                    cvv_tamanho: [4],
                    mask: "#### #### #### ####",
                },
                diners: {
                    name: "Diners Club",
                    numero: ["301", "305", "36", "38"],
                    numero_intervalo: [],
                    numero_tamanho: [14, 16],
                    cvv_tamanho: [3],
                    mask: "#### #### #### ####",
                },
                amex: {
                    name: "Americacan Express",
                    numero: ["34", "37"],
                    numero_intervalo: [],
                    numero_tamanho: [15],
                    cvv_tamanho: [4],
                    mask: "#### ###### #####",
                },
                jcb: {
                    name: "Jcb",
                    numero: ["35"],
                    numero_intervalo: [],
                    numero_tamanho: [16],
                    cvv_tamanho: [3],
                    mask: "#### #### #### ####",
                },
                hipercard: {
                    name: "Hipercard",
                    numero: ["38", "60"],
                    numero_intervalo: [],
                    numero_tamanho: [13, 16, 19],
                    cvv_tamanho: [3],
                    mask: "#### #### #### #### ####",
                },
                visa: {
                    name: "Visa",
                    numero: ["4"],
                    numero_intervalo: [],
                    numero_tamanho: [13, 16],
                    cvv_tamanho: [3],
                    mask: "#### #### #### ####",
                },
                mastercard: {
                    name: "Mastercard",
                    numero: [
                        "5",
                        "6304",
                        "6304",
                        "6759",
                        "6731",
                        "6761",
                        "6762",
                        "6763",
                        "6771",
                        "508116",
                    ], // numeros 6... é para os cartões maestro
                    numero_intervalo: [["222100", "272099"]],
                    numero_tamanho: [16],
                    cvv_tamanho: [3],
                    mask: "#### #### #### ####",
                },
            },
        };
    },
    watch: {
        async carrinho(e) {
            if (this.$axios.getLocal("session")) {
                return;
            }

            // RESUMO CUPOM
            this.resumo = {
                qtd: 0,
                subtotal: 0,
                valor: 0,
                valor_cupom: 0,
                valor_taxa: 0,
                valor_combo: 0,
                cupom: "",
            };

            e.forEach((car) => {
                car.produtos.forEach((item) => {
                    let taxa = item.taxa ? Number(item.taxa) : 0;
                    let total = item.total ? Number(item.total) : 0;
                    let valor = item.valor
                        ? Number(item.valor)
                        : Number(item.preco);
                    let valor_cupom = item.valor_cupom
                        ? Number(item.valor_cupom)
                        : 0;
                    let valor_sem_desconto = item.valor_sem_desconto
                        ? Number(item.valor_sem_desconto)
                        : 0;
                    let subtotal =
                        Number(
                            item.tem_desconto || item.valor_cupom
                                ? valor + valor_cupom
                                : item.preco
                        ) * Number(item.qtd);
                    let valor_combo =
                        item.combo || item.idcombo
                            ? Number(
                                  valor_sem_desconto - (valor + valor_cupom)
                              ) * Number(item.qtd)
                            : 0;

                    this.resumo.qtd += Number(item.qtd);
                    this.resumo.subtotal += subtotal + valor_combo;
                    this.resumo.valor += total
                        ? total * Number(item.qtd)
                        : Number(item.preco) * Number(item.qtd);

                    // atualizando valores do cupom quando tem sessão
                    if (this.$axios.getLocal("session")) {
                        this.resumo.cupom = item.cupom;
                        this.resumo.valor_cupom +=
                            Number(item.qtd) * valor_cupom;
                    }

                    if (taxa > 0) {
                        this.resumo.valor_taxa +=
                            Number(taxa) * Number(item.qtd);
                        if (!this.$axios.getLocal("session")) {
                            this.resumo.valor +=
                                Number(taxa) * Number(item.qtd);
                        }
                    }

                    if (item.combo || item.idcombo) {
                        this.resumo.valor_combo += valor_combo;
                    }
                });
            });
        },
    },
    methods: {
        async getCarrinhoQtdReservados() {
            // FUNC: getCarrinhoQtdReservados
            if (!this.$axios.getLocal("session")) {
                let car = this.$axios.getLocal("local_carrinho");
                if (car) {
                    let qtd = 0;
                    car.data.forEach((item) => {
                        item.produtos.forEach((prod) => {
                            qtd += prod.qtd;
                        });
                    });
                    this.qtdReservada = qtd;
                    return qtd;
                }
            } else {
                this.qtdReservada =
                    await this.$superingresso.getCarrinhoQtdReservados();

                return this.qtdReservada;
            }
        },

        async removeProdutoCarrinho(idmovimentacao, idprod) {
            // FUNC: removeProdutoCarrinho
            return await this.$superingresso
                .removeProdutoCarrinho(idmovimentacao, idprod)
                .then(() => {
                    this.atualizaCarrinho(false, "RE;AP;TP");
                });
        },

        async selecao(item, apre, checkout = false) {
            if (!this.$regras.add(item, apre)) {
                return;
            }

            if (
                !this.$axios.getLocal("session") &&
                this.$config.dados.carrinhoLocal
            ) {
                this.carrinhoLocal(item, apre, "selecao").then(() => {
                    this.atualizaCarrinho(false);
                });

                return;
            }
            this.$facebook.addCarrinho(item);

            const i = { ...item };

            new Util().snackbar(`${this.$t_("ATUALIZANDO_CARRINHO")}`);

            await this.setCarrinho(i, apre, checkout, "selecao")
                .then(async (response) => {
                    if (response.idmovimentacao) {
                        // RESGATA O CUPOM AO ADCIONAR NO CARRINHO
                        if (this.resumo.cupom) {
                            await this.$superingresso.aplicarCupom(
                                response.idmovimentacao,
                                this.resumo.cupom
                            );
                        }
                    }
                    this.atualizaCarrinho(false);
                })
                .catch((erro) => {});
        },

        async add(item, apre, checkout) {
            // FUNC: add

            if (!this.$regras.add(item, apre)) {
                return;
            }

            if (
                !this.$axios.getLocal("session") &&
                this.$config.dados.carrinhoLocal
            ) {
                this.carrinhoLocal(item, apre, "add").then(() => {
                    this.atualizaCarrinho(false);
                });

                return;
            }

            this.$facebook.addCarrinho(item);

            const i = item;

            await this.setCarrinho(i, apre, checkout, "add").then(
                async (response) => {
                    if (response.idmovimentacao) {
                        item.qtd++;

                        // RESGATA O CUPOM AO ADCIONAR NO CARRINHO
                        if (this.resumo.cupom) {
                            await this.$superingresso.aplicarCupom(
                                response.idmovimentacao,
                                this.resumo.cupom
                            );
                        }

                        this.atualizaCarrinho(false);
                        return;
                    }
                    // item.qtd-- // CASO PASSE DO LIMITE PARAMETRIZADO VOLTA -1
                }
            );
        },

        async minus(item, apre, checkout) {
            // FUNC: minus

            if (item.qtd > 0) {
                if (this.$config.dados.carrinhoLocal) {
                    if (!this.$axios.getLocal("session")) {
                        const i = item;
                        this.carrinhoLocal(i, apre, "minus").then(() => {
                            this.atualizaCarrinho(false);
                        });

                        return;
                    }
                }

                item.qtd--;
                const i = item;
                await this.setCarrinho(i, apre, checkout, "minus").then(
                    (response) => {
                        if (response.idmovimentacao) {
                            this.atualizaCarrinho(false);
                            return;
                        }
                    }
                );
            }
        },

        async carrinhoLocal(produto, apre, tipo) {
            // FUNC: carrinhoLocal

            if (apre.dt_hora && apre.dt_hora.hora) {
                produto.dt_hora = apre.dt_hora.hora;
            }

            if (tipo == "add") {
                produto.qtd++;
            }

            if (tipo == "minus") {
                produto.qtd--;
            }

            let car_temp = [
                {
                    valorTotal: 0,
                    qtd: 0,
                    idapresentacao: apre.idapresentacao,
                    dt_apre: apre.dt_apre,
                    dscapre: apre.dscapre,
                    produtos: [produto],
                },
            ];

            if (this.$axios.getLocal("local_carrinho")) {
                let car = this.$axios.getLocal("local_carrinho").data;
                let exit = await car.find(
                    (item) => item.idapresentacao == apre.idapresentacao
                );

                // INSERE NO CARRINHO CASO NÃO EXISTA A APRESENTAÇÃO

                if (exit === undefined) {
                    car.push(car_temp[0]);
                    //this.$axios.setLocal('carrinho', car)
                    this.$axios.setLocal("local_carrinho", car);
                    return true;
                }

                // INSERE UM NOVO PRODUTO CASO NÃO EXISTA NA MESMA APRESENTAÇÃO
                else {
                    let prodexit = await exit.produtos.find(
                        (item) => item.idproduto == produto.idproduto
                    );

                    if (prodexit === undefined) {
                        exit.produtos.push(produto);

                        car.forEach((WE) => {
                            if (WE.idapresentacao == apre.idapresentacao) {
                                WE.produtos = exit.produtos;
                            }
                        });

                        this.$axios.setLocal("carrinho", car);
                        this.$axios.setLocal("local_carrinho", car);
                        return true;
                    }
                }

                // FAZ UPDATE CASO EXISTA A APRESENTAÇÃO E O PRODUTO NO CARRINHO LOCAL

                car_temp = car;

                car_temp.forEach((item, i, c) => {
                    if (item.idapresentacao == apre.idapresentacao) {
                        item.produtos.find((prod, index, object) => {
                            if (prod && prod.idproduto == produto.idproduto) {
                                if (tipo == "add" && prod.qtd < 10) {
                                    prod.qtd++;
                                } else if (tipo == "minus" && prod.qtd > 0) {
                                    prod.qtd--;
                                } else if (tipo == "selecao") {
                                    prod.qtd = Number(produto.qtd);
                                }
                            }

                            /* if(prod && tipo == 'selecao' && produto.qtd == 0) {
                                prod.qtd = 0
                            } */

                            if (prod && prod.qtd == 0) {
                                object.splice(index, 1);
                            }
                        });
                        if (item.produtos.length == 0) {
                            c.splice(i, 1);
                        }
                    }
                });
            }
            if (car_temp && car_temp[0]) {
                this.$google.addCarrinho(car_temp[0].produtos);
            }

            this.$axios.setLocal("local_carrinho", car_temp);
            this.carrinho = car_temp;

            await this.getCarrinhoQtdReservados();

            return true;
        },

        async setCarrinho(_item, apre, checkout, tipo) {
            // FUNC: setCarrinho

            const produto = { ..._item };
            const cartoes = [];

            if (
                this.AddPedido.info_compra &&
                this.AddPedido.info_compra.pessoa_cartoes &&
                this.AddPedido.info_compra.pessoa_cartoes.length > 0 &&
                produto.cartoes.length != produto.qtd &&
                produto.cartao_dsc
            ) {
                // this.$util.snackbar('Selecione os cartões de recarga ou retirada no local', 'error');

                for (let x = 0; x < produto.qtd; x++) {
                    if (produto.cartoes.length != produto.qtd) {
                        produto.cartoes.push({
                            cartao: "Retirada",
                            classe: null,
                            codigo: null,
                            dsccartao: null,
                            idcartao: null,
                            titular: null,
                            disabled: false,
                        });
                    }
                }
            }

            let carrinho = {
                valorTotal: 0,
                qtd: 0,
                idapresentacao: null,
                idtipoproduto: null,
                idmovimentacao: null,
                exp: 0,
                produtos: [],
            };

            // CANCELA OS PRODUTOS PARA DEPOIS ADICIONAR

            if (checkout) {
                carrinho.idapresentacao = apre.idapresentacao;
            } else if (this.carrinho) {
                carrinho.idapresentacao =
                    this.$carrinho.AddPedido.idapresentacao;
            }

            if (tipo == "add") {
                carrinho.produtos.push({
                    idproduto: produto.idproduto,
                    qtd: 1,
                    extra:
                        typeof apre.dt_apre == "object"
                            ? { segundo_dia: apre.dt_apre[1] }
                            : null,
                });
            } else {
                let c_ = this.carrinho;
                let itemCancelado = { cancelar: [] };

                c_.forEach((item) => {
                    if (
                        item.idapresentacao == apre.idapresentacao ||
                        item.idapresentacao == carrinho.idapresentacao
                    ) {
                        item.cancelar.forEach((prods) => {
                            if (prods.idproduto == produto.idproduto) {
                                itemCancelado.cancelar.push({
                                    iditem: prods.iditem,
                                });
                                itemCancelado.idmovimentacao =
                                    item.idmovimentacao;
                            }
                        });
                    }
                });

                if (itemCancelado.cancelar.length > 0) {
                    this.$google.removeCarrinho(itemCancelado.cancelar);
                    await this.cancelarReservaDocarrinho(itemCancelado);
                }

                if (produto.cartoes && produto.cartoes.length > 0) {
                    produto.cartoes.forEach((cards, inds) => {
                        cartoes.push(cards);
                        if (cards.idcartao == null) {
                            delete cartoes[inds];
                        }
                    });
                }

                if (cartoes.cartoes && cartoes.cartoes.length > 0) {
                    cartoes.forEach((cards, inds) => {
                        if (cards.idcartao == null) {
                            cartoes[inds] = null;
                        }
                    });
                }

                if (tipo == "selecao") {
                    carrinho.produtos.push({
                        cartoes: cartoes || [],
                        idproduto: produto.idproduto,
                        idapresentacao: carrinho.idapresentacao,
                        qtd: produto.qtd,
                        extra: cartoes || [],
                        total: produto.preco_ctaxa || produto.preco,
                        dsceven: apre.dscapre || "SuperIngresso",
                        dscproduto: produto.dscproduto || "Tickets",
                    });
                } else {
                    return true;
                }
            }

            if (produto.qtd == 0) {
                return;
            }

            this.showAddPedido = false;

            let bloco = this.AddPedido.bloco_ids
                ? this.AddPedido.bloco_ids
                : false;

            let param = [carrinho.produtos, carrinho.idapresentacao, bloco];

            this.$google.addCarrinho(carrinho.produtos);

            return await this.$superingresso.setCarrinho(param);
        },

        async setCarrinhoLocal(carrinho) {
            // FUNC: setCarrinhoLocal

            let param = [carrinho.produtos, carrinho.idapresentacao, false];

            return await this.$superingresso.setCarrinho(param);
        },

        async atualizaCarrinho(item = false) {
            // FUNC: atualizaCarrinho
            this.qtdReservada = await this.getCarrinhoQtdReservados();

            await this.getCarrinhoQtdReservados();

            const root = document.getElementById("app").__vue__;

            if (this.$axios.getLocal("session")) {
                if (root.$route.name == "MeusPedidos") {
                    await this.$axios
                        .get(
                            ENDPOINTS.TICKETSCART +
                                `?status=${encodeURIComponent(
                                    this.statusPagina
                                )}&page=${this.pagina}&results_per_page=${
                                    this.totalResultados
                                }&fetch_items=true`
                        )
                        .then((response) => {
                            if (response) {
                                this.pedidos = response.tickets;
                                this.paginacao = response.total_pages;
                            }
                        })
                        .catch((err) => console.log(err));
                    return;
                }
                await this.$axios
                    .get(
                        ENDPOINTS.PEDIDOSPAGINADOS +
                            `?status=${encodeURIComponent("RE;AP;TP")}&page=${
                                this.pagina
                            }&results_per_page=${
                                this.totalResultados
                            }&fetch_items=true`
                    )
                    .then((response) => {
                        if (response) {
                            this.resumo = {
                                ...response.page_summary,
                                qtd: response.page_summary.quantity,
                                valor: response.page_summary.total,
                            };
                            if (
                                response.tickets &&
                                response.tickets.length > 0
                            ) {
                                response.tickets.forEach((res) => {
                                    res.exp = res.produtos[0].dt_exp;
                                    res.dscapre = res.produtos[0].dscapre;
                                    res.dt_apre = res.produtos[0].dt_apre;
                                    res.idapresentacao = res.produtos[0].idapre;
                                    res.res = res.dthr_reserva;
                                    res.cancelar = res.produtos;
                                    res.valorTotal = res.total;
                                    res.produtos.forEach((prod) => {
                                        prod.dscproduto = prod.produto;
                                        prod.idproduto = prod.idprod;
                                        prod.idapresentacao = prod.idapre;
                                    });
                                });

                                this.resumo_lista = response.tickets;
                                let arry = response.tickets.map(
                                    (x) => x.idmovimentacao
                                );
                                let mov = arry.filter((element, index) => {
                                    return arry.indexOf(element) === index;
                                });
                                let obj = [];
                                mov.forEach((idmovimentacao) => {
                                    response.tickets.forEach((item) => {
                                        if (
                                            idmovimentacao ==
                                            item.idmovimentacao
                                        ) {
                                            obj.push({
                                                idmovimentacao: idmovimentacao,
                                                valor: item.produtos
                                                    .map((t) => t.total)
                                                    .reduce(
                                                        (accumulator, value) =>
                                                            Number(
                                                                accumulator
                                                            ) + Number(value),
                                                        0
                                                    ),
                                            });
                                        }
                                    });
                                    response.tickets.forEach((item) => {
                                        item.mov = mov.length;
                                        if (
                                            idmovimentacao ==
                                            item.idmovimentacao
                                        ) {
                                            item.textMovimentacoes =
                                                `Pagar: ${this.$util.asMoney(
                                                    obj
                                                        .map((t) =>
                                                            idmovimentacao ==
                                                            t.idmovimentacao
                                                                ? t.valor
                                                                : 0
                                                        )
                                                        .reduce(
                                                            (
                                                                accumulator,
                                                                value
                                                            ) =>
                                                                Number(
                                                                    accumulator
                                                                ) +
                                                                Number(value),
                                                            0
                                                        )
                                                )}` +
                                                (" - " + item.dscapre) +
                                                (" - " +
                                                    this.$moment(
                                                        item.dt_apre
                                                    ).format(
                                                        "DD/MM/YYYY - HH:mm"
                                                    )) +
                                                (item.status == "TP"
                                                    ? " - Tentativa de PGTO"
                                                    : "");
                                        }
                                    });
                                });

                                let selecionado = response.tickets.find(
                                    (item) =>
                                        item.idmovimentacao ==
                                        this.carrinho_finalizar_select
                                );
                                this.carrinho = response.tickets;

                                if (
                                    response.tickets &&
                                    response.tickets.length > 0 &&
                                    (this.carrinho_finalizar_select == "" ||
                                        !selecionado)
                                ) {
                                    this.carrinho_finalizar_select =
                                        response.tickets[0].idmovimentacao;
                                }

                                this.$axios.setLocal("carrinho", this.carrinho);
                            }
                            if (response.tickets.length == 0) {
                                this.$axios.remove("carrinho");
                            }
                        }
                    })
                    .catch((err) => console.log(err));
            } else {
                // RESUMO CUPOM

                let carrinho_venda = [];

                if (this.$axios.getLocal("local_carrinho")) {
                    carrinho_venda =
                        this.$axios.getLocal("local_carrinho").data;
                }

                if (carrinho_venda) {
                    carrinho_venda.forEach((WE) => {
                        if (WE.produtos && WE.produtos.length > 0) {
                            WE.produtos.forEach((valores) => {
                                WE.valorTotal +=
                                    Number(valores.preco) * Number(valores.qtd);
                                WE.qtd += Number(valores.qtd);
                            });
                        }
                    });

                    if (carrinho_venda.length > 0) {
                        this.carrinho = carrinho_venda;
                    }
                }
            }

            if (item) {
                this.getApresentacao(item);
            }
        },

        cancelarReserva(RE) {
            // FUNC: cancelarReserva
            RE.forEach((item) => {
                this.$superingresso
                    .cancelarReserva(item.idmovimentacao)
                    .then(() => {
                        this.atualizaCarrinho();
                    });
            });
        },

        cancelarReservaDocarrinho(RE) {
            // FUNC: cancelarReservaDocarrinho
            let itens = [];

            RE.cancelar.forEach((item) => {
                itens.push(item.iditem);
            });

            this.$superingresso
                .removeProdutoCarrinho(RE.idmovimentacao, itens)
                .then(() => {
                    this.atualizaCarrinho();
                });
        },

        getApresentacao(item) {
            // FUNC: getApresentacao
            this.$superingresso
                .getTipoProdutos(item.idapresentacao)
                .then((response) => {
                    if (response.mapa && response.mapa.help) {
                        this.help = response.mapa.help;
                    }
                    if (response && response.tipo_produtos) {
                        // LOADING INGRESSOS
                        let res = response;
                        this.AddPedido = [];

                        let produtos = res.tipo_produtos;
                        produtos.forEach((prod) => {
                            prod.produtos.forEach((i) => {
                                if (!i.qtd) {
                                    i.qtd = 0;
                                }
                            });
                        });

                        // ATUALIZANDO O MODAL DE ACORDO COM O CARRINHO
                        let qtd_total = 0;
                        if (this.carrinho) {
                            let car_temp = this.carrinho;

                            produtos.forEach((prod) => {
                                car_temp.find((car) => {
                                    prod.produtos.forEach((produtos) => {
                                        produtos.block_qtd = 0;
                                        if (
                                            car.produtos &&
                                            car.produtos.length > 0
                                        ) {
                                            car.produtos.forEach(
                                                (produtos_temp) => {
                                                    // console.log(produtos,produtos.idproduto,produtos_temp.idproduto)
                                                    if (
                                                        produtos.idproduto ==
                                                        produtos_temp.idproduto
                                                    ) {
                                                        produtos.qtd += 1;
                                                        qtd_total += 1;
                                                        // console.log(produtos)
                                                        // console.log(produto.qtd)
                                                    }
                                                }
                                            );
                                        }
                                    });
                                });
                            });
                        }

                        produtos.forEach((prod) => {
                            prod.produtos.forEach((produtos) => {
                                produtos.block_qtd = qtd_total;
                            });
                        });

                        item.qtd = qtd_total;

                        this.AddPedido = item;
                        this.AddPedido.tipo_produtos = produtos;
                        this.AddPedido.total = 0;
                        this.showAddPedido = true;
                        this.ApresencacoesArray.push(this.AddPedido);
                    }

                    item.loading = false;
                    this.atualizaCarrinho();
                });
        },

        cancelarReservaItem(car, item) {
            // FUNC: cancelarReservaDocarrinho

            let itens = [];
            let cancelar = car.cancelar.filter(
                (i) => i.idprod == item.idproduto
            );

            cancelar.forEach((item) => {
                itens.push(item.iditem);
            });

            this.$superingresso
                .removeProdutoCarrinho(car.idmovimentacao, itens)
                .then(() => {
                    this.atualizaCarrinho();
                });
        },
        cancelarReservaItemUnico(car, item) {
            // TODO: cancelarReservaDocarrinho

            let itens = [];

            let cancelar = car.cancelar.filter(
                (i) => i.idprod == item.idproduto
            );

            cancelar.forEach((item) => {
                if (item.iditem && item.qtd > 0) {
                    item.qtd--;
                    itens.push(item.iditem);
                    this.atualizaCarrinho();
                }
            });

            this.$superingresso
                .removeProdutoCarrinho(car.idmovimentacao, itens[0])
                .then(() => {
                    this.atualizaCarrinho();
                });
        },

        getQtdPerApresentacao(idapresentacao) {
            // FUNC: getQtdPerApresentacao
            let qtd = 0;
            if (idapresentacao && this.$axios.getLocal("carrinho")) {
                let car_temp = this.$axios.getLocal("carrinho").data;
                car_temp.forEach((car) => {
                    if (car.idapresentacao == idapresentacao) {
                        qtd = car.qtd;
                    }
                });
            }
            return qtd;
        },

        getFormaspagamento(protect = null) {
            // FUNC: getFormaspagamento

            if (this.$axios.getLocal("carrinho")) {
                this.carrinho_finalizar = this.$axios.getLocal("carrinho").data;
                let formaPagamentos = [];

                this.carrinho_finalizar.forEach((item) => {
                    if (item.status != "EX") {
                        this.$superingresso
                            .getFormasPagamento(
                                this.carrinho_finalizar_select,
                                protect
                            )
                            .then((response) => {
                                if (response && response.gateways.length == 0) {
                                    this.$config.snackbar.text =
                                        "Erro no gateway";
                                    this.$config.snackbar.show = true;
                                    this.$config.snackbar.color = "red";
                                    return;
                                }

                                formaPagamentos = response.gateways;

                                // REMOVENDO OS ARRAY REPETIDOS
                                var novaArr = formaPagamentos.filter(function (
                                    este,
                                    i
                                ) {
                                    return formaPagamentos.indexOf(este) === i;
                                });

                                novaArr.forEach((item, index) => {
                                    item.nome = item.nome.toUpperCase();
                                    if (item.nome.length > 18) {
                                        item.nome = `${
                                            index + 1
                                        } - ${item.nome.substring(0, 18)}`;
                                    }
                                });

                                this.$config.gateway_raw = response;
                                this.$config.gateway = novaArr;
                                this.$config.gatewaySelected =
                                    this.$config.gateway[0];

                                if (
                                    this.$config.gatewaySelected.parcelas
                                        .length > 0
                                ) {
                                    this.$config.gatewaySelected.parcelaSelect =
                                        this.$config.gatewaySelected.parcelas[0];
                                }

                                // IMPORTANDO O SDK NO NEO PAY
                                let script = document.createElement("script");

                                if (
                                    this.$config.gatewaySelected &&
                                    this.$config.gatewaySelected
                                        .files_include &&
                                    this.$config.gatewaySelected
                                        .files_include[0]
                                ) {
                                    script.src =
                                        this.$config.gatewaySelected.files_include[0];
                                    document.head.prepend(script);
                                }
                            });
                    }
                });
            }
        },

        setBandeiras(obj) {
            if (obj && obj.length > 0) {
                let bandeiras = obj.map((item) => item.bandeiras_aceitas);
                let flags = [];

                bandeiras = bandeiras.join(",");
                bandeiras = bandeiras.split(",");

                var novaArr = bandeiras.filter(function (este, i) {
                    return bandeiras.indexOf(este) === i && i != "";
                });

                var arraySemVazios = novaArr.filter(function (i) {
                    return i;
                });

                if (arraySemVazios && arraySemVazios.length > 0) {
                    arraySemVazios.forEach((item) => {
                        flags.push(`${item}.png`);
                    });
                }

                this.bandeiraspagamento = flags;
            }
        },

        getBandeira(e) {
            e = e.replace(/\s/g, "");
            var n;
            var a = 0;

            for (var r in this.infoCartoes) {
                for (var u = 0; u < this.infoCartoes[r].numero.length; u++) {
                    var o,
                        l,
                        s = this.infoCartoes[r].numero[u];
                    if (s.length > e.length) {
                        o = e;
                        l = s.substring(0, e.length);
                    } else {
                        o = e.substring(0, s.length);
                        l = s;
                    }
                    if (o == l && s.length > a) {
                        n = r;
                        a = s.length;
                    }
                }
                for (
                    var u = 0;
                    u < this.infoCartoes[r].numero_intervalo.length;
                    u++
                ) {
                    var o,
                        l1,
                        l2,
                        s1 = this.infoCartoes[r].numero_intervalo[u][0];
                    var s2 = this.infoCartoes[r].numero_intervalo[u][1];

                    if (s1.length > e.length) {
                        o = e;
                        l1 = s1.substring(0, e.length);
                        l2 = s2.substring(0, e.length);
                    } else {
                        o = e.substring(0, s1.length);
                        l1 = s1;
                        l2 = s2;
                    }

                    if (
                        parseInt(o) >= l1 &&
                        parseInt(o) <= l2 &&
                        s1.length > a
                    ) {
                        n = r;
                        a = s1.length;
                    }
                }
            }

            var CartaoSelecionado = n
                ? a <= e.length
                    ? n == null
                        ? "unknown"
                        : n
                    : "unknown"
                : "unknown";

            if (CartaoSelecionado == "unknown") {
                return CartaoSelecionado;
            }

            return {
                bandeira: CartaoSelecionado,
                cvv_tamanho: this.infoCartoes[CartaoSelecionado].cvv_tamanho[0],
                mask: this.infoCartoes[CartaoSelecionado].mask,
                name: this.infoCartoes[CartaoSelecionado].name,
            };
        },

        async pagar() {
            // FUNC: pagar

            if (!this.$axios.getLocal("session")) {
                this.$usuario.showModalLogin = true;
                this.$axios.setLocal("gorouter", "Checkout");
                return;
            }

            let index = [
                this.$axios
                    .getLocal("carrinho")
                    .data.findIndex(
                        (item) =>
                            item.idmovimentacao ==
                            this.carrinho_finalizar_select
                    ),
            ];

            if (this.$config.gatewaySelected.length == 0) {
                this.$config.alertWarn(
                    "Erro na forma de pagamento, Você deve vincular os portadores ou contatar o SAC!"
                );
                return;
            }
            if (this.interesseTermos == false) {
                this.$config.alertWarn(this.$t_("deve_aceitar"));
                document
                    .getElementById("termos-compra")
                    .scrollIntoView({ behavior: "smooth", block: "end" });
                return;
            }

            // VALIDANDO
            if (this.$config.conf.recaptcha_ativo) {
                if (
                    this.$config.conf.site_key_recaptcha &&
                    this.$config.conf.recaptcha_ativo
                ) {
                    try {
                        this.dados.recaptcha =
                            await window.grecaptcha.getResponse();
                    } catch {
                        window.grecaptcha.execute();
                    }

                    if (this.dados.recaptcha == "") {
                        window.grecaptcha.execute();
                        document
                            .getElementById("termos-recaptcha")
                            .scrollIntoView({
                                behavior: "smooth",
                                block: "end",
                            });
                        return;
                    }
                }
            }
            // PREPARANDO OS DADOS PARA O ENVIO DO PAGAMENTO

            let dados = {
                idpag: "",
                token: "",
                idmovimentacao: this.carrinho_finalizar[index].idmovimentacao,
                idformapagamento: this.$config.gatewaySelected.id_criptografado,
                recaptcha:
                    this.$config.conf.site_key_recaptcha &&
                    this.$config.conf.recaptcha_ativo
                        ? await window.grecaptcha.getResponse()
                        : "",
                ingresso:
                    this.ingresso_select == "ETICKET"
                        ? null
                        : { idendereco: this.endereco.idendereco },
                gateway: this.$config.gatewaySelected,
                parcela: this.$config.gatewaySelected.parcelaSelect
                    ? this.$config.gatewaySelected.parcelaSelect.parcela
                    : 1,
                card: {
                    number: "",
                    cvc: "",
                    holderName: "",
                    expiryMonth: "",
                    expiryYear: "",
                    generationtime: new Date().toISOString(),
                },
            };
            this.$facebook.addInfoPgto();
            this.$facebook.eventoPersonalizado("GatewayPagamento", {
                name: this.$config.gatewaySelected.nome,
                value: this.resumo.valor,
            });

            this.$google.checkoutStepPagamento(
                this.carrinho_finalizar[0].produtos,
                this.resumo.cupom
            );

            this.loading = true;

            this.$superingresso
                .iniciarPagamento(
                    dados.idmovimentacao,
                    dados.idformapagamento,
                    dados.recaptcha,
                    dados.ingresso,
                    dados.parcela
                )
                .then((response) => {
                    if (response && response.token) {
                        this.ImplyPayNeo(response.token);
                    }

                    if (response && !response.token && response.idpag) {
                        this.$google.checkoutStepFinalizarCarrinho(
                            this.carrinho_finalizar[0].produtos,
                            this.carrinho_finalizar[0].idmovimentacao,
                            this.resumo.cupom
                        );
                        this.$superingresso.concluirPagamento(
                            dados.idmovimentacao
                        );
                        this.$carrinho.resumo.qtd = 0;
                        this.$carrinho.resumo.valor = 0;
                        this.$axios.remove("carrinho");
                    }

                    this.loading = false;
                });
        },

        async ImplyPayNeo(token) {
            // FUNC: ImplyPayNeo

            let ambiente = this.$config.gatewaySelected.ambiente;
            let wsUrl = "wss://payws.imply.com.br/";
            let httpUrl = "https://pay.imply.com.br/";

            switch (ambiente) {
                case "dev":
                    ambiente = "test";
                    wsUrl = "ws://payneohomologacao.imply.com.br/test";
                    if (location.protocol === "https:") {
                        wsUrl = "wss://payneohomologacao.imply.com.br/test";
                    }
                    httpUrl =
                        "https://y86eq337g3.execute-api.us-east-1.amazonaws.com/test";
                    break;
                case "sandbox":
                    ambiente = "staging";
                    wsUrl = "wss://payneohomologacao.imply.com.br/staging";

                    if (location.protocol === "https:") {
                        wsUrl = "wss://payneohomologacao.imply.com.br/staging";
                    }
                    httpUrl =
                        "https://y86eq337g3.execute-api.us-east-1.amazonaws.com/staging";

                    break;
                case "live":
                    ambiente = "prod";
                    break;
            }

            const self = this;

            window.cancelarReserva = () => {
                if (document.getElementById("imply-pay-app")) {
                    document.getElementById("imply-pay-app").remove();
                }
                self.$axios.modal.show = false;
                self.cancelarReserva(self.carrinho);
                setTimeout(() => {
                    location.href = "/";
                }, 500);
            };

            const ImplyPay = new window.imply.Pay({
                token: token,
                wsUrl: wsUrl,
                httpUrl: httpUrl,
                language: "pt",
                ambiente: ambiente, // Alterar conforme o back
                colors: {
                    primary: "#60B33A",
                },
                onClose: (response) => {
                    switch (response.status) {
                        case "fail":
                            console.warn("Falha no pagamento");
                            this.$config.conf.site_key_recaptcha &&
                            this.$config.conf.recaptcha_ativo &&
                            self.$config.dados.recaptcha == "visible"
                                ? window.grecaptcha.reset()
                                : window.grecaptcha.execute();
                            let select = "";

                            self.$config.gateway.forEach((formapagamento) => {
                                select += `<option value="${formapagamento.idformapag}">${formapagamento.nome}</option>`;
                            });
                            let index =
                                self.$carrinho.carrinho_finalizar_select;
                            self.$config.recaptchaModal = true;
                            self.$axios.modal.showclose = false;
                            self.$axios.modalBar(
                                "Cancelamento",
                                `
                                <div style="position: relative" class="mt-3">
                                    <label class="block uppercase">${self.$t_(
                                        "label_cancelar_pagamento"
                                    )}</label>
                                    <select style="position: relative; z-index: 2; border: 1px solid gray; padding: 11px; width: 100%; border-radius: 6px;" id="gateway-modal" onchange="getParcelas()">
                                        ${select}
                                    </select>
                                    <div id="parcelas-modal"></div>
                                    <i aria-hidden="true" class="v-icon notranslate mdi mdi-menu-down theme--light" style="right: 5px; top: 35px; position: absolute;"></i>
                                </div>
     
                                <small class="my-5">
                                    ${self.$t_("text_cancelamento")}
                                </small>

                                <button class="checkout-button1 button mt-2 white--text" style="background-color: ${
                                    self.$config.dados.corprimaria
                                }; color: white; width: 100%" onclick="pagar()">
                                    ${self.$t_("finalizar_pedido")}
                                </button>

                                <button class="checkout-button1 button mt-2" style="background-color: red; color: white; width: 100%" onclick="cancelarReserva()">
                                    ${self.$t_("remover")}
                                </button>
                               
                                `
                            );
                            setTimeout(() => {
                                self.$carrinho.carrinho_finalizar_select =
                                    index;
                            }, 1500);

                            setTimeout(() => {
                                if (window.setParcelas) {
                                    window.setParcelas();
                                }
                                window.grecaptcha.render("modalpagamento", {
                                    sitekey: `${self.$config.conf.site_key_recaptcha}`,
                                    id: `'${new Date().getTime()}'`,
                                    theme: "light",
                                });
                            }, 2000);
                            break;

                        case "success":
                            console.warn("Sucesso no pagamento");
                            // Captura os dados da compra e envia ao Google Analytics
                            self.$google.checkoutStepFinalizarCarrinho(
                                this.carrinho_finalizar[0].produtos,
                                this.carrinho_finalizar[0].idmovimentacao,
                                this.resumo.cupom
                            );
                            self.$superingresso.concluirPagamento(
                                this.carrinho_finalizar[0].idmovimentacao
                            );
                            self.$carrinho.resumo.qtd = 0;
                            self.$carrinho.resumo.valor = 0;
                            self.$axios.remove("carrinho");

                            setTimeout(() => {
                                self.$axios.rota("MeusPedidos");
                                //location.reload(true)
                            }, 500);
                            break;

                        case "pending":
                            console.warn("Pagamento pendente");
                            this.$superingresso.concluirPagamento(
                                this.carrinho_finalizar[0].idmovimentacao
                            );
                            self.$axios.rota("MeusPedidos");
                            break;
                    }
                },
            });
            window.onbeforeunload = function (event) {
                if (self.pagamentoRealizado == true) {
                    window.cancelarReserva = null;
                } else {
                    window.cancelarReserva();
                }
                return "Saindo da página!";
            };

            window.setParcelas = () => {
                self.$config.gatewaySelected = self.$config.gateway.find(
                    (item) =>
                        item.idformapag ==
                        document.getElementById("gateway-modal").value
                );
                if (
                    self.$config.gatewaySelected &&
                    self.$config.gatewaySelected.parcelas &&
                    self.$config.gatewaySelected.parcelas.length > 0 &&
                    document.getElementById("gateway-modal-parcelas")
                ) {
                    self.$config.gatewaySelected.parcelaSelect =
                        self.$config.gatewaySelected.parcelas.find(
                            (item) =>
                                item.parcela ==
                                document.getElementById(
                                    "gateway-modal-parcelas"
                                ).value
                        );
                }
            };

            window.pagar = () => {
                if (
                    this.$config.conf.site_key_recaptcha &&
                    this.$config.conf.recaptcha_ativo
                ) {
                    if (window.grecaptcha.getResponse() == "") {
                        window.grecaptcha.execute();
                    }
                }
                window.setParcelas();
                self.$axios.modal.show = false;
                self.pagar();
            };

            window.getParcelas = () => {
                self.$config.gatewaySelected = self.$config.gateway.find(
                    (item) =>
                        item.idformapag ==
                        document.getElementById("gateway-modal").value
                );
                if (
                    self.$config.gatewaySelected.parcelas &&
                    self.$config.gatewaySelected.parcelas.length > 0
                ) {
                    let parcelas = document.getElementById("parcelas-modal");
                    let select = "";
                    self.$config.gatewaySelected.parcelas.forEach((par) => {
                        select += `<option value="${par.parcela}">${
                            par.parcela
                        }x ${self.$util.asMoney(par.valor)}</option>`;
                    });
                    parcelas.innerHTML = `
                        <div style="position: relative" class="mt-3">
                            <label class="block uppercase">${self.$t_(
                                "PARCELA"
                            )}</label>
                            <select style="position: relative; z-index: 2; border: 1px solid gray; padding: 11px; width: 100%; border-radius: 6px;" id="gateway-modal-parcelas" onchange="setParcelas()">
                                ${select}
                            </select>
                            <i aria-hidden="true" class="v-icon notranslate mdi mdi-menu-down theme--light" style="right: 5px; top: 35px; position: absolute;"></i>
                        </div>
                    `;
                } else {
                    document.getElementById("parcelas-modal").innerHTML = "";
                }
            };
        },
        async setAgendamento(dados) {
            // TODO: setAgendamento
            return await this.$axios.post(ENDPOINTS.SETAGENDAMENTO, dados);
        },
        async getAgendamento(dados) {
            return await this.$axios.post(ENDPOINTS.GETAGENDAMENTO, dados);
        },
        async getQtdAgenda(dados) {
            return await this.$axios.post(ENDPOINTS.GETQTD, dados);
        },
    },
});
