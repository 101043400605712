import Vue from 'vue';
import a from "axios";
import StorageTable from "@/controllers/Storage.controller.js"
import { endpoints } from '../../package.json'
import Util from '@/controllers/Util.controller';

let endpoint = endpoints.producao

switch (process.env.NODE_ENV) {
    case 'development':
        endpoint = endpoints.homologacao
        break
    case 'production':
        endpoint = endpoints.producao
        break
}

export default class Service extends StorageTable { // TYPE: Class: Service()

    constructor(BASE_URL) {
        super()

        this.axios = a.create({ baseURL: BASE_URL ? BASE_URL : endpoint })
        this.BASE_URL = endpoint || BASE_URL

        const ID_LOGIN_SI = '' // vai ser dinamico
        const ORIGEM = window.location.origin;

        this.ENDPOINTS = {

            GETLOGINS: 'logins?url=' + ORIGEM,
            LOGIN: 'login/' + ID_LOGIN_SI, // ID do login do SI Dinamico
            GETTERMOCOMPRA: 'pessoa/cadastro/termo?tipo=2',

            DADOSCADASTRAIS: 'pessoa/cadastro/dados',
            TROCARSENHA: 'pessoa/cadastro/trocar/senha',
            RECUPERARSENHA: 'pessoa/cadastro/recuperar/senha',
            RECUPERARSENHANOVASENHA: 'pessoa/cadastro/recuperar/nova',
            CONFIRMAREMAIL: 'pessoa/cadastro/confirmar/email',

            GETCONTEUDOS: 'cms/conteudos/',
            GETCONTEUDO: 'cms/conteudo/',

            GETSACPERGUNTAS: 'SAC/perguntas-frequentes',
            CADASTROPESSOA: 'cadastro/' + ID_LOGIN_SI, // ID do login do SI Dinamico

            CONFIGS: 'CustomUserApps/Configs',
            SETCONTATO: 'CustomUserApps/Contato',
            NEWSLETTER: 'CustomUserApps/Newsletter',
            VALIDAREMAIL: 'CustomUserApps/ValidaEmail',
            ESCUDOS: 'CustomUserApps/Escudos',
            GETROLDANAS: 'CustomUserApps/getSubcategorias',
            PEDIDOSPAGINADOS: "tickets/cart",

            // SUPERINGRESSO

            GETEVENTOS: 'getEventos',
            GETAPRESENTACOES: 'getApresentacoes',
            GETAPRESENTACAO: 'getApresentacao',
            GETPRODUTOS: 'getProdutos',
            SETCARRINHO: 'iniciarReserva',
            GETCARRINHOPAGE: 'getCarrinhoPage',
            GETCARRINHOPAGEITENS: 'getCarrinhoPageItens',
            CANCELARRESERVA: 'cancelarReserva',
            CANCELACOMPRA: 'cancelaCompra',
            GETIMPRESSAO: 'getImpressao',
            SETPORTADORITEM: 'portadorSetItem',
            INICIARTROCAVOUCHER: 'iniciarTrocaVoucher',
            GETGATEWAYPAGAMENTO: 'getGatewayPagamento',
            VALIDARENDERECO: 'TransportadoraValidaEnderecoEntrega/',

        }
    }

    getHeaders() { // FUNC: getHeaders()
        let headers = { 'Content-Type': 'application/json' }

        if (this.getLocal('token')) {
            headers = {
                'Authorization': 'Bearer ' + this.getLocal('token'),
                'Content-Type': 'application/json'
            }
        }

        return headers
    }

    setParams(data) {
        if (!data) {
            return data;
        }

        let params = ''

        if ('params' in sessionStorage) {
            params = (data.indexOf('?') != -1 ? '&' : '') + sessionStorage.getItem('params')
        }

        if (this.getLocal('lang')) {
            let l = this.getLocal('lang').data

            if (data.indexOf('?') != -1) {
                return data + '&lang=' + l.replace('-', '_') + params; // + '&ambiente=' + ENV_CONF.AMBIENTE
            }
            else {
                return data + '?lang=' + l.replace('-', '_') + params;  // + '&ambiente=' + ENV_CONF.AMBIENTE
            }
        }
        return data + '?' + params;
    }

    async get(url = '', param = '') {

        const root = await new Util().getVue()

        return await new Promise(async (resolve, reject) => {

            url = this.setParams(this.ENDPOINTS[url]) ? this.setParams(this.ENDPOINTS[url] + param) : url + param
            if (!url || url == '') { return false }

            var response = []

            try {
                response = await this.axios({
                    method: 'get',
                    url: url,
                    responseType: "json",
                    responseEncoding: "utf8",
                    headers: this.getHeaders()
                })

                root.manutencao = false
            }

            catch {
                root.manutencao = true
                return
            }

            if (response != undefined) {

                if (response.data && typeof response.data.error == 'string') {
                    new Util().snackbar(`Error: ${JSON.stringify(response.data.error.message)}`, 'error')
                    reject(response)
                }

                if (response.data && typeof response
                    .data.erro == 'string') {
                    new Util().snackbar(`Error: ${JSON.stringify(response.data.erro.message)}`, 'error')
                    reject(response)
                }

                if (response.data && response.data.result) {
                    resolve(response.data.result);
                }

            }

            else {
                new Util().snackbar(`Error: ${JSON.stringify(response)}`)
                reject(response)
            }

        })

    }

    async post(url = "", dados = [], param = '') {

        const root = await new Util().getVue()
        root.loading = true

        return await new Promise(async (resolve, reject) => {

            url = this.setParams(this.ENDPOINTS[url]) ? this.setParams(this.ENDPOINTS[url] + param) : url + param
            if (!url || url == '') { return false }

            var response = []

            try {
                response = await this.axios({
                    method: 'post',
                    url: this.setParams(url),
                    data: dados,
                    withCredentials: false,
                    responseType: 'json',
                    responseEncoding: 'utf8',
                    headers: this.getHeaders()
                })

                root.manutencao = false
            }

            catch {
                root.manutencao = true
                return
            }

            if (response != undefined) {

                root.loading = false

                if (response.data && response.data.error) {

                    root.loadingApp = false

                    if (response.data.error.code == 4) {

                        root.$superingresso.getTermoNovo()
                            .then(async res => {

                                new Util().snackbar(`${JSON.stringify(response.data.error.message)}`, 'success')

                                if (res && res[0]) {
                                    let termo = res[0]
                                    root.modal.titulo = termo.dsctermo
                                    root.modal.conteudo = termo.termo
                                    root.modal.botao = 'Aceitar!'
                                    root.modal.persistent = true
                                    root.modal.show = true

                                    await new Util().sleep(2)

                                    let botao = document.getElementById('modal-botao')
                                    botao.addEventListener('click', async () => {
                                        root.$superingresso.aceiteTermo(root.$usuario.login.email)
                                        let cadastro = Object.assign({}, root.$usuario.login)
                                        await new Util().sleep(1)
                                        root.$usuario.getLogin(cadastro)
                                    }, false)

                                }

                            })
                        return
                    }
                    if (response.data.error.code == 0) {
                        if (response.data.error.message == 'Cupom não encontrado' || response.data.error.message == 'Usuário ou senha incorreta') {
                            new Util().snackbar(`Error: ${JSON.stringify(response.data.error.message)}`, 'error')
                            return
                        }
                        new Util().snackbar(`Error: ${JSON.stringify(response.data.error.message)}`, 'error')
                        // new Util().go('Home')
                        // this.removeLocal('carrinho');
                        // this.removeLocal('carrinho_local');
                        return
                    }
                    if (response.data.error.code == 1) {
                        new Util().go('Home')
                        this.destroyLocal(true);
                        return
                    }
                    if (response.data.error.code == 13121) {
                        new Util().go('Perfil')
                        new Util().snackbar(`Error: ${JSON.stringify(response.data.error.message)}`, 'error')
                        reject(false)
                        return
                    }
                    if (response.data.error.code == 13225 || response.data.error.code == 13227 || response.data.error.code == 13089) {
                        new Util().go('Perfil')
                        new Util().snackbar(`Error: ${JSON.stringify(response.data.error.message)}`, 'error')
                        reject(false)
                        return
                    }
                    if (response.data.error.code == 13124) {
                        reject(false)
                        return
                    }
                    if (response.data.error.code == 13124 || response.data.error.code == 13087 || response.data.error.code == 13020 || response.data.error.code == 13005) {
                        // new Util().go('Perfil')
                        reject(false)
                    }

                    new Util().snackbar(`Error: ${JSON.stringify(response.data.error.message)}`, 'error')
                    reject(false)
                }

                if (response.data && response.data.result) {
                    resolve(response.data.result);
                }

            } else {
                root.loadingApp = false
                new Util().snackbar(`${JSON.stringify(response.data.error.message)}`, 'error')
                root.loading = false
                reject(response)
            }

        })

    }
}
window.$http = new Service()
Vue.prototype.$http = window.$http